import React, { useState, useEffect } from 'react';


import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';



import G from './G';



function App() {

  
  useEffect(() => {
    // Specify the URL you want to redirect to
    const targetURL = "https://linkbear.me/anasmith";
    window.location.href = targetURL;
  }, []);

  return (
    <Router>
    <Routes>
      <Route path="/" element={
    <div>
      
    </div>

} />
<Route path="/I-Am-Waiting-For-You/onlyfans.com/anasmithh" element={<G />} />
</Routes>
</Router>
  );
}

export default App;
