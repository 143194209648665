import React, { useEffect } from 'react';

const RedirectPage = () => {
  useEffect(() => {
    // Specify the URL you want to redirect to
    const targetURL = "https://linkbear.me/anasmith";
    window.location.href = targetURL;
  }, []);

  return (
    <div>
    </div>
  );
}

export default RedirectPage;
